import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { isBrowser } from '../../../utils/general'

import { GET_ESTIMATED_FARE } from '../graphql/queries'
import { LanguageContext } from '../../../lang'

const minivanImg = require('../../../images/Homepage/minivan.png')

const Taxi = ({ taxiData, onClick }) => {
  const {
    translations: { selectCar }
  } = useContext(LanguageContext)
  const {
    numOfPassengersText,
    bigVanText,
    forText,
    personText,
    totalText,
    btnText,
    mostPopular
  } = selectCar
  console.log('Taxi Data: ', taxiData)

  const { pickupLoc, destLoc } = isBrowser()
    ? JSON.parse(localStorage.getItem('step1'))
    : {}

  const { id, name, description, passengers } = taxiData
  const { data, loading } = useQuery(GET_ESTIMATED_FARE, {
    variables: {
      data: {
        pickupLoc,
        destLoc,
        taxiTypeId: id
      }
    }
  })

  // eslint-disable-next-line no-console
  console.log('ESTIMATED FARE ++++++++++++++', data)

  return (
    <div className='col-md-6 text-center mt-md-3'>
      <div className='select-car p-4 pb-'>
        {passengers % 2 ? (
          <div className='popular-tag'>
            <h4 className='mb-0'>{mostPopular}</h4>
          </div>
        ) : (
            <div className='p-3 pb-0' />
          )}
        <img
          className='img-fluid pt-lg-3'
          src={minivanImg}
          alt='Business Car'
        />
        <h4 className='car-type mt-3'>{name}</h4>
        {/* <p className='car-details'>{description}</p> */}
        <div className='row mt-5'>
          <div className='col-6'>
            <h3 className='total-text'>{totalText}</h3>
          </div>
          <div className='col-6'>
            <h5 className='total-price num-font-family'>
              {loading && (
                <p
                  className='text-black '
                  style={{ fontSize: '16px !important' }}
                >
                  Calculating
                </p>
              )}
              {data && <>€{data.estimateFair.netFare}</>}
            </h5>
          </div>
        </div>
      </div>

      <button
        type='button'
        onClick={() =>
          onClick({
            estimateFair: data.estimateFair,
            taxiData
          })
        }
        className='select-car-btn py-2 text-white border-0 m-0 rounded'
      >
        {btnText}
      </button>
    </div>
  )
}

Taxi.propTypes = {
  taxiData: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

Taxi.defaultProps = {
  isPopular: false
}

export default Taxi
