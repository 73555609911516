// step 2
import React, { useState, useContext, useEffect } from "react";
import { navigate } from "gatsby";
import "./index.css";
import { useQuery } from "@apollo/react-hooks";
import { GET_TAXIES } from "../graphql/queries";
import Spinner from "../../Common/Spinner";
import { isBrowser } from "../../../utils/general";
import BookingHeader from "./bookingHeader";
import Taxi from "./taxi";
import { LanguageContext } from "../../../lang";

function useCounter() {
  const [noOfPassengers, setNoOfPassengers] = useState(0);

  const handlePassengerBtn = (isIncrement = false) => {
    if (isIncrement) {
      if (noOfPassengers === 8) return;
      setNoOfPassengers(noOfPassengers + 1);
    } else {
      if (noOfPassengers === 0) return;
      setNoOfPassengers(noOfPassengers - 1);
    }
  };

  return { noOfPassengers, handlePassengerBtn };
}

const TaxiSelection = () => {
  const {
    translations: { selectCar },
  } = useContext(LanguageContext);
  const {
    numOfPassengersText,
    totalText,
    bigVanText,
    forText,
    personText,
    btnText,
  } = selectCar;

  const { noOfPassengers, handlePassengerBtn } = useCounter();
  const { loading, error, data } = useQuery(GET_TAXIES);

  if (error) console.log("Something Failed in car selection: ", error);

  const handleNavigation = ({ taxiData, estimateFair }) => {
    const step2 = {
      taxiData,
      estimateFair,
      noOfPassengers,
    };

    // eslint-disable-next-line no-unused-expressions
    isBrowser() && localStorage.setItem("step2", JSON.stringify(step2));
    console.log("||||||||||||||||", data);
    navigate("/booking-time");
  };

  const RenderTaxies = () => {
    if (!data || data.length)
      return <h4 className="text-center"> No Taxies Found</h4>;

    return data.taxies
      .filter((taxi) => noOfPassengers <= taxi.passengers)
      .map((taxi, idx) => (
        <Taxi taxiData={taxi} key={idx} onClick={handleNavigation} />
      ));
  };

  return (
    <section id="book-form">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <BookingHeader currentStep="booking-details" price={"0.00"} />
          </div>
        </div>
        <div className=" d-flex justify-content-center">
          <div className="row select-car-row d-flex justify-content-center mt-5 ">
            <div className="col-12 passengers-wrapper">
              <p className="passengers-text">{numOfPassengersText}</p>
              <div className="passengers-count-wrapper">
                <button
                  type="button"
                  className="btn btn-default passengers-icon"
                  onClick={() => handlePassengerBtn()}
                >
                  <i className="fa fa-minus" />
                </button>
                <p className="passenger-number">{noOfPassengers}</p>
                <button
                  type="button"
                  className="btn btn-default passengers-icon"
                  onClick={() => handlePassengerBtn(true)}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>

            {loading && <Spinner />}
            {!loading && data && noOfPassengers !== 0 && <RenderTaxies />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TaxiSelection;
