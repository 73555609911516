import React from 'react';
import Header from '../components/Header/index';
import SEO from '../components/SEO/seo';
import TaxiSelection from '../components/BookingForm/Form-Steps/taxiSelection';

const SelectCar = () => (
  <>
    <Header />
    <SEO title="Select Car" />
    <TaxiSelection />
  </>
);

export default SelectCar;
